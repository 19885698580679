import { initializeApp } from "firebase/app";
import {
  getFirestore,
  enableMultiTabIndexedDbPersistence,
  //enableIndexedDbPersistence,
} from "firebase/firestore";
const firebaseConfig = {
  apiKey: "AIzaSyChKTdWyMPaDus0Ezo0oS9Ift2u5MdrFnM",
  authDomain: "slared.firebaseapp.com",
  projectId: "slared",
  storageBucket: "slared.appspot.com",
  messagingSenderId: "849996692636",
  appId: "1:849996692636:web:b589ad4ae636a897038159",
  measurementId: "G-D1DJ2LCWDN",
};

// Initialize Firebase
initializeApp(firebaseConfig);
const db = getFirestore();
//No aparece esta opción en la documentación..
enableMultiTabIndexedDbPersistence(db).catch((err) => {
  console.log(err);
});
//enableIndexedDbPersistence(db).catch((err) => {
//console.log(err);
//});
