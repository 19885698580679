import { createRouter, createWebHashHistory } from "vue-router";
import { useUserStore } from "@/store/userStore";

const routes = [
  {
    path: "/",
    name: "appshell",
    redirect: "/home",
    component: () => import("../views/appShell.vue"),
    children: [
      {
        path: "home",
        name: "home",
        component: () => import("../views/homeView.vue"),
        meta: {
          requiresAuth: true,
          desc: "Inicio",
        },
      },
      {
        path: "adet",
        name: "adet",
        redirect: "/adet/list",
        component: () => import("../views/adet/adetView.vue"),
        meta: {
          requiresAuth: true,
          desc: "ADET",
        },
        children: [
          {
            path: "list",
            name: "adet_list",
            component: () => import("../views/adet/adetListView.vue"),
          },
          {
            path: "proc",
            name: "adet_proc",
            component: () => import("../views/adet/adetProcView.vue"),
          },
          {
            path: "reg/:uuid/:index?",
            name: "adet_reg",
            component: () => import("../views/adet/adetRegView.vue"),
          },
          {
            path: "start/:uuid?",
            name: "adet_start",
            component: () => import("../views/adet/adetStartView.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/loginView.vue"),
    meta: {
      desc: "Login",
    },
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("../views/logoutView.vue"),
    meta: {
      desc: "Logout",
    },
  },
  {
    path: "/noaccess",
    name: "noaccess",
    component: () => import("../views/noaccessView.vue"),
    meta: {
      desc: "Noaccess",
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "nofound",
    component: () => import("../views/nofoundView.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to) => {
  const userStore = useUserStore();
  if (to.meta.requiresAuth && !userStore.uid) return "/login";
});

export default router;
