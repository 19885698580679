import { defineStore } from "pinia";
import { useStorage } from "@vueuse/core";
import { getAuth, onAuthStateChanged } from "firebase/auth";

export const useUserStore = defineStore("userStore", {
  state: () =>
    useStorage("userStore", { uid: null, name: null, unsubscribe: null }),
  actions: {
    setuser(payload) {
      this.uid = payload.uid;
      this.name = payload.name;
    },
    clearuser() {
      this.uid = null;
      this.name = null;
    },
    binduser() {
      const auth = getAuth();
      this.unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
          user
            .getIdTokenResult()
            .then((tokenResult) => {
              if (tokenResult.claims.allow_access) {
                this.uid = user.uid;
                this.name = user.displayName;
              } else this.clearuser();
            })
            .catch(() => {});
        } else this.clearuser();
      });
    },
    unbinduser() {
      this.unsubscribe();
    },
  },
});
